// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/briankulp/Sites/briankulp.co/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/briankulp/Sites/briankulp.co/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lets-talk-js": () => import("/Users/briankulp/Sites/briankulp.co/src/pages/lets-talk.js" /* webpackChunkName: "component---src-pages-lets-talk-js" */),
  "component---src-pages-nice-words-js": () => import("/Users/briankulp/Sites/briankulp.co/src/pages/nice-words.js" /* webpackChunkName: "component---src-pages-nice-words-js" */),
  "component---src-pages-services-js": () => import("/Users/briankulp/Sites/briankulp.co/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-success-js": () => import("/Users/briankulp/Sites/briankulp.co/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-work-js": () => import("/Users/briankulp/Sites/briankulp.co/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/briankulp/Sites/briankulp.co/.cache/data.json")

